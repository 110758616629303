import { IonContent, IonLabel, IonToast, IonInput,IonIcon, IonRow, IonCol, IonFooter, IonHeader, IonPage, IonButton, IonImg, IonSpinner, IonSelectOption, IonSelect, IonGrid, IonItem, IonText, IonCheckbox, IonTitle } from '@ionic/react';
import React, { Component,useState }  from 'react';
import axios from 'axios';
import './Login.css';
import './Index.css'
import { menuController } from '@ionic/core';
import { Plugins } from '@capacitor/core';
import { toastController } from '@ionic/core';
import { IonReactRouter } from '@ionic/react-router';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline } from 'ionicons/icons';
const { App } = Plugins;
const { Network } = Plugins;

let history = [{}]

class Logout extends Component {
  props: any = {};
  state = {menuOpen: false, triedLogin: false, falloLogin: false, loading: false, society: false, errorInternet: false, calculadora: "", debug: false, usuario: "", password: "", errorLogin: false};
  LOGIN_URL = 'https://milofood.com:5555/login';
  //LOGIN_URL = 'http://127.0.0.1:5555/login';
  COOKIE_NAME = 'milo-login'
  startAtLogin = true


  constructor(props: any) {
    super(props);
    this.props = props
    this.onKeyUp = this.onKeyUp.bind(this)
    //userContext = this.props.userContext
    //getBack = this.props.userContext.getBack
    history = this.props.props.history
    
    //menuController.enable(this.state.menuOpen)
    
    //console.log(this.props)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
  }
  
  handleInputChange = (e: any) => {
    
    if(e.currentTarget.outerHTML.includes("Usuario")) {
      this.state.usuario = e.target.value
    } else if(e.currentTarget.outerHTML.includes("Contraseña")){
      this.state.password = e.target.value
    }
    this.setState(this.state)
  }
  
  onKeyUp(e:any) {
    //console.log(this.state.codigo)
    if(e.keyCode === 13) {
      this.logear()
      //this.signIn(this.props.userContext)
    }
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => {this.props.props.history.push('/login')}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={peopleOutline}></IonIcon>
            ⠀     <span className="textAcceso">Acceso clientes</span>
                </div>
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/index');}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/contactar');}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/transparencia');}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/catalogo');}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Acceso</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  
  

  async llamadaWS() {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    let res = await axios.post(this.LOGIN_URL, /*JSON.stringify(*/{username: this.state.usuario, password: this.state.password}/*)/*, config*/);
    console.log(res)
    this.crearCookie(this.COOKIE_NAME, "asdasdasd", 30)
    this.leerCookies()
    //this.props.props.history.push('/tab1');
  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined) {
      //this.state.falloLogin = true
      return false
    }
    return true
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  leerCookies() {
      let cookies = document.cookie.split(" ")
      for(let i = 0; i < cookies.length; i++)
        if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
          return cookies[i]
      return undefined
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderPiePagina() {
    return (
      <div className="fullFooter">
        <div className="colorPiePagina">
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="footerMobile">
          <IonGrid className="gridFooter">
            <IonRow>
              <IonCol>
                <div>
                  <IonLabel>CONTACTAR</IonLabel>
                  <IonItem lines="none" color="grey" className="colorApartadosPiePagina">
                    <div>
                      <div className="subapartadosContactar">
                        <p><IonIcon className="" icon={locationOutline}></IonIcon> Calle El Sino 81</p>
                        <p className="colorSubapartadosContactar">⠀⠀La Esperanza, El Rosario</p>
                        <p>⠀⠀38290 Santa Cruz de Tenerife</p>
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={callOutline}></IonIcon> Teléfono: +34 922 297 618
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={mailOutline}></IonIcon> fabrica@milofood.com
                      </div>
                    </div>
                  </IonItem>
                </div>
              </IonCol>
              <IonCol><div className="centroFooter"><IonImg className="logoFooter" src="assets/img/logos/logo_footer.png"></IonImg></div></IonCol>
              <IonCol>
                <div className="fullWidthClass">
                  <IonCol className= " derechaFooter" size="12">Elaborado en Canarias</IonCol>
                  <IonCol className= " derechaFooter" size="12">Entregas de 24 a 48 horas</IonCol>
                  <IonCol className= " derechaFooter" size="12">Asociados a la plataforma Voxel</IonCol>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    )
  }

  async logear() {
    this.state.triedLogin = true
    if(!this.successfullyLogin()){
      try {
      await this.llamadaWS()
      }
      catch(error:any) {
        this.state.triedLogin = true
        this.state.falloLogin = true;
        this.setState(this.state)
        this.setState({falloLogin: true, triedLogin: true})

      }
      if(!this.successfullyLogin())
        this.state.errorLogin = true;
      else
        this.props.props.history.push('/catalogo');
    }
    else
      this.props.props.history.push('/catalogo')
    this.setState(this.state)
  }

    sleep = (milliseconds:number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

  logout() {
      this.sleep(1500)
    this.borrarCookie()
  }
  
  render() {
    
    this.borrarCookie()
    console.log("logout")
    
    //this.props.props.history.push('/login')
    return (
      <IonPage onClick={() => { 
        menuController.enable(true)
          this.setState({menuOpen: false})
      }}>
        <IonHeader>
        {this.renderEncabezado()}
        </IonHeader>
        <IonContent className="ion-padding">
        <div>
          <div>
            <div>
            <IonRow><IonCol size="12"><div className="titulo">Formulario de acceso</div></IonCol></IonRow>
            </div>
            <div className="login-class errorLogin">
              { this.state.triedLogin && this.state.falloLogin ? <div>El usuario o contraseña son incorrectos.</div> : " "}   
            </div>
            <div className="login-class">
              <IonInput onKeyUp={this.onKeyUp} className="estiloInput" name="userApp" placeholder="Usuario" value={this.state.usuario} onIonChange={(e: any) => this.handleInputChange(e)}></IonInput>
            </div>
            <div className="login-class">
              <IonInput onKeyUp={this.onKeyUp} className="estiloInput" name="pswApp" placeholder="Contraseña" type="password" value={this.state.password} onIonChange={(e: any) => this.handleInputChange(e)}></IonInput>
            </div>
            <div className="boton-login">
                       
            <IonRow>
              <IonCol></IonCol><IonCol></IonCol><IonCol></IonCol><IonCol></IonCol>
              <IonButton className="login-button" onClick={() => { 
                this.logear()
              }} fill="solid" color="danger">
                Iniciar sesión
              </IonButton>
              
                
              <IonCol></IonCol><IonCol></IonCol><IonCol></IonCol><IonCol></IonCol>
            </IonRow>
            </div>
          </div>
          <IonRow><IonCol><div></div></IonCol></IonRow>
          <IonRow><IonCol><div></div></IonCol></IonRow>
          <IonRow><IonCol><div></div></IonCol></IonRow>
          <IonRow>
              <IonCol></IonCol><IonCol></IonCol><IonCol></IonCol><IonCol></IonCol>
          {this.state.loading ? <IonSpinner></IonSpinner> : <div></div>}
          <IonCol></IonCol><IonCol></IonCol><IonCol></IonCol><IonCol></IonCol>
            </IonRow>
          </div>  
        </IonContent>
        <IonFooter>
          {this.renderPiePagina()}
        </IonFooter>
      </IonPage>
    )
  }
}

class LogoutInterno extends React.Component
{
  props: any = {}

  constructor(props: any) {
    super(props)
    this.props = {props}
  }

  render() {
    return (
        <Logout props={{...this.props}}/>
    )
  }
}

export default LogoutInterno;