import { IonContent, IonTitle, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonPage, IonSearchbar, IonButton, IonSelectOption, IonToolbar } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { toastController } from '@ionic/core';
import { CatalogoContext } from '../contexts/CatalogoContext';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import './Catalogo.css'
const INITIAL_STATE = {
    menuOpen: false,
    catalogo: [],
    filtro: ""
};

class Subfamilia extends React.Component {
  props: any = {};
  COOKIE_NAME = 'milo-login'
  state = {menuOpen: false, filtro: ""};
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
    //menuController.enable(this.state.menuOpen)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
    /*
    this.state.filtro = ""
    //this.setState({filtro: ""})
    console.log("reseteando 1")
    this.setState(this.state)
    */
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
    /*this.state.filtro = ""
    //this.setState({filtro: ""})
    console.log("reseteando 2")
    this.setState(this.state)*/
  }

  buscarArticulos() {
    let articulos = []
      for(let j = 0; j < this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias.length; j++)
        for(let k = 0; k < this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[j].articulos.length; k++)
          if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[j].articulos[k].nombreArticulo.toUpperCase().includes(this.state.filtro.toUpperCase()) ||
          this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[j].articulos[k].codigoArticulo.toUpperCase().includes(this.state.filtro.toUpperCase()))
            articulos.push({articulo: this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[j].articulos[k], indexFamilia: this.props.catalogoContext.familiaSeleccionada, indexSubfamilia: j, indexArticulo: k})
    return articulos
    //let catalogo: [{subfamilias: [{nombreSubfamilia: "", rutaImagen: "", articulos: [{codigoArticulo: "", nombreArticulo: "", rutaImagen: ""}]}], nombreFamilia: "", rutaImagen: ""}]
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie(); this.props.props.history.push('/login')/*this.props.props.history.push('/logout')*/ }}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                </div>
            </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.props.history.push('/index'); this.state.filtro = ""}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/index'); this.state.filtro = ""}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/contactar'); this.state.filtro = ""}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/transparencia'); this.state.filtro = ""}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.props.history.push('/catalogo'); this.state.filtro = ""}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Subfamilia</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
}

  renderPiePagina() {
    return (
        <div>
            <div className="colorPiePagina">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
              </IonRow>
            </IonGrid>
            </div>
        </div>
    )
  } 

  renderSubFamilias(subfamilias:any) {
    //console.log(subfamilias)
    if(this.state.filtro === "")
      return subfamilias.map((subfamilia:any) =>(
        <IonItem className="cursosSeleccionable sizeCategoria">
          <IonGrid>
          <IonRow>
            <IonImg className="sizeFotoCategoria" src={"assets/img/Articulos/" + subfamilia.nombreSubfamilia + ".png"}/*src={subfamilia.rutaImagen}*/ onClick={() => {
                this.props.catalogoContext.subfamiliaSeleccionada = subfamilia.idVector
                this.props.props.history.push('/articulos')
                this.state.filtro = ""
            }}></IonImg>
          </IonRow>
          <IonRow> <IonCol> <div className="pieDeFoto">{subfamilia.nombreSubfamilia}</div></IonCol></IonRow>
          </IonGrid>
        </IonItem>
      ))
    return this.buscarArticulos().map((articulo:any) => (
        <IonItem className="cursosSeleccionable sizeCategoria">
          <IonGrid>
            <IonRow>
              <IonImg className="sizeFotoCategoria" src={"assets/img/Articulos/" + articulo.articulo.codigoArticulo + ".png"} /*src={articulo.rutaImagen}*/  onClick={() => {
                  this.props.catalogoContext.familiaSeleccionada = articulo.indexFamilia
                  this.props.catalogoContext.subfamiliaSeleccionada = articulo.indexSubfamilia
                  this.props.catalogoContext.articuloSeleccionado = articulo.indexArticulo
                  this.props.props.history.push('/detallesArticulo')
                  this.state.filtro = ""
              }}></IonImg>
            </IonRow>
            {this.mostrarCaracteristicas(articulo)}
            <IonRow> <IonCol><div className="pieDeFoto">{articulo.articulo.codigoArticulo} - {articulo.articulo.nombreArticulo}</div></IonCol></IonRow>
          </IonGrid>
        </IonItem>
      ))
  }

  
  mostrarConservacion(articulo:any) {
    if(articulo.conservacion === "Congelado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/frozenIcon.png"></IonImg></IonCol>
    if(articulo.conservacion === "Refrigerado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/chilledIcon.png"></IonImg></IonCol>
    return <div></div>
      /*
    if(articulo.conservacion === "Seco")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol>
      */
  }

  mostrarCaracteristicas(articulo:any) {
    return <IonRow><IonCol>⠀</IonCol>{this.mostrarConservacion(articulo)}<IonCol>⠀</IonCol></IonRow>
    console.log(articulo.conservacion)
    return <div></div>
    return <IonRow><IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol></IonRow>

  }

  renderPath(){
    return (
      <IonRow>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/catalogo')
            this.state.filtro = ""
          }}>
          Catálogo
        </div>
        <IonIcon icon={chevronForwardOutline}></IonIcon>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/subfamilia')
            this.state.filtro = ""
          }}>
          {this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia}
        </div>
      </IonRow>
    )
  }

  render() {
    if(this.props.catalogoContext.catalogo.length === 0) {
      this.props.props.history.push('/login')
      this.state.filtro = ""
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] === undefined) {
      this.props.props.history.push('/login')
      this.state.filtro = ""
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia === undefined) {
      this.props.props.history.push('/login')
      this.state.filtro = ""
      return <div></div>
    }
      return <IonPage onClick={() => { 
        menuController.enable(true)
        this.setState({menuOpen: false})
      }}>
      <IonHeader className="correccionDeFondo">
        {this.renderEncabezado()}
        <div className="headerPath">
          {this.renderPath()}
        </div>
        <IonSearchbar value={this.state.filtro} onIonChange={(e: any) => this.setState({filtro: e.target.value})} placeholder="Buscar artículo" animated></IonSearchbar>
        </IonHeader>
      <IonContent className="ion-padding">
        <IonRow>
        {this.renderSubFamilias(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias)}
        </IonRow>
        
    </IonContent>
    {this.renderPiePagina()}
    </IonPage>
  }
}

class SubfamiliaInterno extends React.Component
{
  props: any = {}

  constructor(props: any) {
    super(props)
    this.props = {props}
  }

  render() {
    
    return (
      <CatalogoContext.Consumer>{ catalogoContext =>
        <Subfamilia props={{...this.props}} catalogoContext={catalogoContext} />
      }</CatalogoContext.Consumer>
    )
  }
}
export default SubfamiliaInterno;