import React, { createContext} from 'react';


const INITIAL_STATE = {
    familiaSeleccionada: 0,
    subfamiliaSeleccionada: 0,
    articuloSeleccionado: 0,
    catalogo: []
};

const DEFAULT_VALUE = {
    familiaSeleccionada: 0,
    subfamiliaSeleccionada: 0,
    articuloSeleccionado: 0,
    catalogo: [],
    getFamiliaSeleccionada: () => {},
    getSubfamiliaSeleccionada: () => {},
    getArticuloSeleccionado: () => {},
    getCatalogo: () => {},
    assignFamiliaSeleccionada: (familia:number) => {},
    assignSubfamiliaSeleccionada: (subfamilia:number) => {},
    assignArticuloSeleccionado: (articulo:number) => {},
    assignCatalogo: (catalogo:any) => {}
}

export const CatalogoContext = createContext(DEFAULT_VALUE);

class CatalogoContextProvider extends React.Component {
    state: any = {};

    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    
    getFamiliaSeleccionada() {
        return this.state.familiaSeleccionada
    }

    getSubfamiliaSeleccionada() {
        return this.state.subfamiliaSeleccionada
    }

    getArticuloSeleccionado() {
        return this.state.articuloSeleccionado
    }

    assignSubfamiliaSeleccionada(subfamilia:number) {
        this.state.subfamiliaSeleccionada = subfamilia
        this.setState(this.state)
    }

    assignFamiliaSeleccionada(familia:number) {
        console.log(this.state)
        //this.state.familiaSeleccionada = familia
        //this.setState(this.state)
        this.setState({familiaSeleccionada: familia})
    }

    assignArticuloSeleccionado(articulo:number) {
        this.state.articuloSeleccionado = articulo
        this.setState(this.state)
    }

    getCatalogo() {
        return this.state.catalogo
    }

    assignCatalogo(catalogo:any) {
        this.state.catalogo = catalogo
        this.setState(this.state)
    }

    render () {
        return (
            <CatalogoContext.Provider value={{...this.state, 
                getFamiliaSeleccionada: this.getFamiliaSeleccionada, 
                getSubfamiliaSeleccionada: this.getSubfamiliaSeleccionada,
                getArticuloSeleccionado: this.getArticuloSeleccionado,
                assignSubfamiliaSeleccionada: this.assignSubfamiliaSeleccionada,
                assignFamiliaSeleccionada: this.assignFamiliaSeleccionada,
                assignArticuloSeleccionado: this.assignArticuloSeleccionado,
                assignCatalogo: this.assignCatalogo,
                getCatalogo: this.getCatalogo
            }}>
                {this.props.children}
            </CatalogoContext.Provider>
        )
    }
}

export default CatalogoContextProvider;