import React, { useState } from 'react';
import { IonImg, IonMenu, IonHeader, IonToolbar, IonContent, IonList, IonCol, IonMenuToggle, IonIcon, IonLabel, IonItem, IonButton, IonRow, IonGrid } from "@ionic/react";
import { RouteComponentProps, withRouter } from 'react-router';
import { toastController } from '@ionic/core';
import { logOutOutline } from 'ionicons/icons';
import '../pages/Index.css';
import { homeSharp, personSharp, documentTextSharp, logInOutline, storefrontSharp } from 'ionicons/icons';
interface Page {
    title: string;
    path: string;
    icon: string;
}

let successfullyLogin = () => {
    let cookie = leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

let leerCookies = () => {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === 'milo-login')
        return cookies[i]
    return undefined
  }

let userContext = { history: [""] }

const pages: Page[] = [
    { title: 'Inicio', path: '/index', icon: homeSharp },
    { title: 'Contactar', path: '/contactar', icon: personSharp },
    { title: 'Transparencia', path: '/transparencia', icon: documentTextSharp },
    { title: 'Catálogo', path: '/catalogo', icon: storefrontSharp },
    { title: 'Acceso clientes', path: '/login', icon: logInOutline },
    { title: 'Cerrar sesión', path: '/login', icon: logOutOutline }

];

type Props = RouteComponentProps<{}>;

const SideMenu = ({ history }: Props) => {
    const actualPage = () => {
        return pages[indexOfPage(history.location.pathname)]
    }

    const indexOfPage = (page:string) => {
        for(let i = 0; i < pages.length; i++)
            if(page === pages[i].path)
                return i
        if(page === "/subfamilia" || page === "/articulos" || page === "/detallesArticulo")
            for(let i = 0; i < pages.length; i++)
                if("/catalogo" === pages[i].path)
                    return i
        return 0
    }

    const successfullyLogin = () => {
        let cookie = leerCookies()
        if(cookie === undefined) {
          //this.state.falloLogin = true
          return false
        }
        return true
      }
    const borrarCookie = () => {
        //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
        crearCookie("milo-login", "", -1)
      }
    
      const leerCookies = () => {
          let cookies = document.cookie.split(" ")
          for(let i = 0; i < cookies.length; i++)
            if(cookies[i].substring(0, cookies[i].indexOf("=")) === "milo-login")
              return cookies[i]
          return undefined
      }
    
      const crearCookie = (name:string, token:string, days:number) => {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        else {
            expires = "";
        }
        document.cookie = name + "=" + token + expires + "; path=/";
      }
    
    const [activePage, setActivePage] = useState(actualPage().title);
    let userRol = '';

    const renderMenuItems = (): JSX.Element[] => {
        return getPages().map((page: Page) => (
            <IonMenuToggle key={page.title} auto-hide="false">
                <IonItem button
                    color={page.title === activePage ? 'medium' : ''}
                    onClick={() => navigateToPage(page)}>
                    <IonIcon slot="start" icon={page.icon}></IonIcon>
                    <IonLabel>
                        {page.title}
                    </IonLabel>
                </IonItem>
            </IonMenuToggle>
        ));
    }

    const navigateToPage = async (page: Page) => {
        if(page.title === "Cerrar sesión") {
            borrarCookie()
            if(history.location.pathname === "/subfamilia" || history.location.pathname === "/articulos" || history.location.pathname === "/detallesArticulo"){
                history.push("/login");
                setActivePage("Acceso clientes");

            }else{
                history.push(history.location.pathname);
            }
        }
        else {
            history.push(page.path);
            setActivePage(page.title);
        }
        
    }

    const getPages = () => {
        if(successfullyLogin()) {
            let newPages = []
            for(let i = 0; i < pages.length; i++)
                if(pages[i].title !== 'Acceso clientes')
                    newPages.push(pages[i])
            return newPages
        }
            
        let newPages = []
            for(let i = 0; i < pages.length; i++)
                if(pages[i].title !== 'Catálogo' && pages[i].title !== 'Cerrar sesión')
                    newPages.push(pages[i])
            return newPages
    }

    const renderMenu = () => {
        return (
            <IonMenu contentId="main">
                <IonHeader>
                    <IonToolbar>
                        <IonImg src="assets/img/logos/logo.jfif"></IonImg>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {renderMenuItems()}
                    </IonList>
                </IonContent>
            </IonMenu>
        )
    }

    return (renderMenu());
}

export default withRouter(
    SideMenu
);