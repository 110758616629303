import { IonContent, IonTitle, IonFooter, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonSearchbar, IonPage, IonButton, IonSelectOption, IonToolbar } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import { toastController } from '@ionic/core';
import { CatalogoContext } from '../contexts/CatalogoContext';
import './Catalogo.css';
const INITIAL_STATE = {
    menuOpen: false,
    catalogo: [],
    filtro: "",
    saliendo: false
};

class Catalogo extends React.Component {
  props: any = {};
  COOKIE_NAME = 'milo-login'
  state = {saliendo: false, filtro: "", menuOpen: false, catalogo: [{subfamilias: [{nombreSubfamilia: "", rutaImagen: "", articulos: [{codigoArticulo: "", nombreArticulo: "", rutaImagen: ""}]}], nombreFamilia: "", rutaImagen: ""}]};
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
    menuController.enable(this.state.menuOpen)
  }

  ionViewDidEnter(): void {
    this.state.saliendo = false
    menuController.enable(false);
  }
  
  ionViewDidLeave(): void {
    //console.log("saliendo")
    this.state.saliendo = false
    menuController.enable(true);
    this.setState(this.state)
  }

  async getCatalogo() {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    //let res = await axios.get("http://192.168.1.145:5400/excelCatalogo");
    let res = await axios.get("https://milofood.com:5555/excelCatalogo");
    //console.log(res)
    return this.montarCatalogo(res.data)
  }

  existeFamilia(catalogo:any, familia:string) {
    for(let i = 0; i < catalogo.length; i++)
      if(catalogo[i].nombreFamilia === familia)
        return i
    return -1
  }

  existeSubfamilia(catalogo:any, indiceFamilia:number, subfamilia:string) {
    for(let i = 0; i < catalogo[indiceFamilia].subfamilias.length; i++)
      if(catalogo[indiceFamilia].subfamilias[i].nombreSubfamilia === subfamilia)
        return i
    return -1
  }

  existeArticulo(catalogo:any, indiceFamilia:number, indiceSubfamilia:number, codigoArticulo:string) {
    for(let i = 0; i < catalogo[indiceFamilia].subfamilias[indiceSubfamilia].articulos.length; i++)
      if(catalogo[indiceFamilia].subfamilias[indiceSubfamilia].articulos[i].codigoArticulo === codigoArticulo) 
        return i
    return -1
  }

  tipoLineaLeida(data:any) {
    if(data.Subfamilia === null)
      return "Familia"
    if(data.Articulo === null)
      return "Subfamilia"
    return "Articulo"
  }

  crearArticulo(catalogo:any, indiceFamilia:number, indiceSubfamilia:number, data:any) {
    let indiceArticulo = this.existeArticulo(catalogo, indiceFamilia, indiceSubfamilia, data.Articulo)
    if(indiceArticulo === -1) {
      catalogo[indiceFamilia].subfamilias[indiceSubfamilia].articulos.push({codigoArticulo: data.Articulo, nombreArticulo: data.NombreArticulo, rutaImagen: data.Ruta, idVector: catalogo[indiceFamilia].subfamilias[indiceSubfamilia].articulos.length, peso: data.Peso, formato: data.Formato, conservacion: data.Conservacion, preparacion: data.Preparacion, observaciones: data.Observaciones})
    }                                                                                                                                                                                                                       //peso: "", formato: "", conservacion: "", preparacion:"", observaciones:""                                            

    return catalogo
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div> 
                {/*
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => {this.props.props.history.push('/login')}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={peopleOutline}></IonIcon>
            ⠀     <span className="textAcceso">Acceso clientes</span>
                </div>   
                */   
                }

                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie();/*this.props.props.history.push('/logout')*/ }}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                 
                </div>
 
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/index'); this.state.filtro = ""}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/contactar'); this.state.filtro = ""}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/transparencia'); this.state.filtro = ""}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.props.history.push('/catalogo'); this.state.filtro = ""}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Catálogo</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  renderPiePagina() {
    return (
        <div>
            <div className="colorPiePagina">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
              </IonRow>
            </IonGrid>
            </div>
        </div>
    )
  } 

  montarFamilias() {
    let catalogo = [{subfamilias: [{idVector: -1, nombreSubfamilia: "", rutaImagen: "", articulos: [{codigoArticulo: "", nombreArticulo: "", rutaImagen: "", idVector: -1, peso: "", formato: "", conservacion: "", preparacion:"", observaciones:""}]}], nombreFamilia: "", rutaImagen: "", idVector: -1}]
    let familias = [ "EMBUTIDOS",
    "QUESOS",
    "ANTIPASTI",
    "APERITIVOS FRIOS",
    "APERITIVOS CALIENTES",
    "ENSALADAS",
    "GAMA FOIE",
    "AHUMADOS Y DEL MAR",
    "CAVIAR",
    "PUDING",
    "SETAS Y TRUFAS",
    "CROQUETAS",
    "GUARNICIONES",
    "ASADOS",
    "CARNES",
    "PLATOS ELABORADOS",
    "DEL MAR",
    "PASTAS",
    "MEJICO",
    "ASIATICOS",
    "FRUTAS",
    "PANES",
    "MERMELADAS",
    "POSTRES",
    "COMPLEMENTOS",
    "FLORES COMESTIBLES",
    "ALGAS MARINAS",
    "BASES Y TARTALETAS",
    "VEGANO Y VEGETARIANO",
    "SALSAS Y PRODUCTOS CANARIOS",
    "VINOS",
    "ALCOHOLES"]
    catalogo = []
    for(let i = 0; i < familias.length; i++)
      catalogo.push({nombreFamilia: familias[i], subfamilias: [], rutaImagen: "", idVector: catalogo.length})
    return catalogo
  }

  buscarArticulos() {
    let articulos = []
    for(let i = 0; i < this.props.catalogoContext.catalogo.length; i++) 
      for(let j = 0; j < this.props.catalogoContext.catalogo[i].subfamilias.length; j++)
        for(let k = 0; k < this.props.catalogoContext.catalogo[i].subfamilias[j].articulos.length; k++)
          if(this.props.catalogoContext.catalogo[i].subfamilias[j].articulos[k].nombreArticulo.toUpperCase().includes(this.state.filtro.toUpperCase()) ||
          this.props.catalogoContext.catalogo[i].subfamilias[j].articulos[k].codigoArticulo.toUpperCase().includes(this.state.filtro.toUpperCase()))
            articulos.push({articulo: this.props.catalogoContext.catalogo[i].subfamilias[j].articulos[k], indexFamilia: i, indexSubfamilia: j, indexArticulo: k})
    return articulos
    //let catalogo: [{subfamilias: [{nombreSubfamilia: "", rutaImagen: "", articulos: [{codigoArticulo: "", nombreArticulo: "", rutaImagen: ""}]}], nombreFamilia: "", rutaImagen: ""}]
  }

  montarCatalogo(data:any) {
    let catalogo = [{subfamilias: [{idVector: -1, nombreSubfamilia: "", rutaImagen: "", articulos: [{codigoArticulo: "", nombreArticulo: "", rutaImagen: "", idVector: -1, peso: "", formato: "", conservacion: "", preparacion:"", observaciones:""}]}], nombreFamilia: "", rutaImagen: "", idVector: -1}]
    catalogo = []
    catalogo = this.montarFamilias()
    for(let i = 0; i < data.length; i++) {

      let indiceFamilia = this.existeFamilia(catalogo, data[i].Familia)
      if(indiceFamilia === -1)  {
        indiceFamilia = catalogo.length
        catalogo.push({nombreFamilia: data[i].Familia, subfamilias: [], rutaImagen: this.tipoLineaLeida(data[i]) === "Familia" ? data[i].Ruta : undefined, idVector: catalogo.length})
      }
      let indiceSubfamilia = this.existeSubfamilia(catalogo, indiceFamilia, data[i].Subfamilia)
      if(indiceSubfamilia === -1 && data[i].Subfamilia !== null) {
        indiceSubfamilia = catalogo[indiceFamilia].subfamilias.length

        catalogo[indiceFamilia].subfamilias.push({nombreSubfamilia: data[i].Subfamilia, rutaImagen: this.tipoLineaLeida(data[i]) === "Subfamilia" ? data[i].Ruta : undefined, articulos: [], idVector: catalogo[indiceFamilia].subfamilias.length})
      }

      switch(this.tipoLineaLeida(data[i])) {
        case "Familia":
          catalogo[indiceFamilia].rutaImagen = data[i].Ruta
          break;
        case "Subfamilia":
          catalogo[indiceFamilia].subfamilias[indiceSubfamilia].rutaImagen = data[i].Ruta
          break;
        case "Articulo":
          catalogo = this.crearArticulo(catalogo, indiceFamilia, indiceSubfamilia, data[i])
          break;
      }
      
    }
    return catalogo
  }

  async updateCatalogo() {
    //let x = await this.getCatalogo()
    //this.state.catalogo = x
    this.props.catalogoContext.catalogo = await this.getCatalogo()
    this.setState(this.state)
  }

  renderCatalogo() {
    if(this.state.filtro === "")
      return this.props.catalogoContext.catalogo.map((categoria:any) => (
        <IonItem className="cursosSeleccionable sizeCategoria">
          <IonGrid>
          <IonRow>
          <IonImg className="sizeFotoCategoria" src={"assets/img/Articulos/" + categoria.nombreFamilia + ".png"} /*src="assets/img/Bebidas.jpg"*/ /*src="assets/img/logos/logo.jfif"*/ onClick={() => {
            //this.props.catalogoContext.assignFamiliaSeleccionada(categoria.idVector)
            this.props.catalogoContext.familiaSeleccionada = categoria.idVector
            this.state.filtro = ""
            this.props.props.history.push('/subfamilia')
          }}></IonImg>
          </IonRow>
          <IonRow> <IonCol> <div className="pieDeFoto">{categoria.nombreFamilia}</div></IonCol></IonRow>
          </IonGrid>
          {/*this.renderSubFamilias(categoria.subfamilias)*/}

        </IonItem>
      ))
    return this.buscarArticulos().map((articulo:any) => (
      <IonItem className="cursosSeleccionable sizeCategoria">
        <IonGrid>
          <IonRow>
            <IonImg className="sizeFotoCategoria" src={"assets/img/Articulos/" + articulo.articulo.codigoArticulo + ".png"} /*src={articulo.rutaImagen}*/  onClick={() => {
                this.props.catalogoContext.familiaSeleccionada = articulo.indexFamilia
                this.props.catalogoContext.subfamiliaSeleccionada = articulo.indexSubfamilia
                this.props.catalogoContext.articuloSeleccionado = articulo.indexArticulo
                this.state.filtro = ""
                this.props.props.history.push('/detallesArticulo')
            }}></IonImg>
          </IonRow>
          {this.mostrarCaracteristicas(articulo)}
          <IonRow> <IonCol><div className="pieDeFoto">{articulo.articulo.codigoArticulo} - {articulo.articulo.nombreArticulo}</div></IonCol></IonRow>
        </IonGrid>
      </IonItem>
    ))
  }

  mostrarConservacion(articulo:any) {
    if(articulo.conservacion === "Congelado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/frozenIcon.png"></IonImg></IonCol>
    if(articulo.conservacion === "Refrigerado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/chilledIcon.png"></IonImg></IonCol>
    return <div></div>
      /*
    if(articulo.conservacion === "Seco")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol>
      */
  }

  mostrarCaracteristicas(articulo:any) {
    return <IonRow><IonCol>⠀</IonCol>{this.mostrarConservacion(articulo)}<IonCol>⠀</IonCol></IonRow>
    console.log(articulo.conservacion)
    return <div></div>
    return <IonRow><IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol></IonRow>

  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
}

  render() {
    if(!this.state.saliendo && !this.successfullyLogin()) {
      this.state.saliendo = true
      this.state.filtro = ""
      console.log(this.props.props.history.location.pathname)
      if(this.props.props.history.location.pathname === "/catalogo")
        this.props.props.history.push('/login')
      setTimeout(() => {  this.state.saliendo = false ; }, 2000);
      
      //return <div></div>
    } else {
        if(this.props.catalogoContext.catalogo.length === 0) {
          this.updateCatalogo()
          //console.log(this.state)
        }
    }
    
    return (
      <IonPage onClick={() => { 
          menuController.enable(true)
          this.setState({menuOpen: false})
        }}>
        <IonHeader className="correccionDeFondo">
        
        {this.renderEncabezado()}
        {/*
          <IonButton onClick={() => {
            for(let i = 0; i < this.props.catalogoContext.catalogo.length; i++) 
              for(let j = 0; j < this.props.catalogoContext.catalogo[i].subfamilias.length; j++)
                for(let k = 0; k < this.props.catalogoContext.catalogo[i].subfamilias[j].articulos.length; k++)
                  console.log(this.props.catalogoContext.catalogo[i].subfamilias[j].articulos[k].codigoArticulo)
          }}>TEST</IonButton>
        */}
        <IonSearchbar onIonChange={(e: any) => this.setState({filtro: e.target.value})} value={this.state.filtro} placeholder="Buscar artículo" animated></IonSearchbar>
      </IonHeader>
        <IonContent className="ion-padding">
          <IonRow>
        {this.renderCatalogo()}
        </IonRow>
        
        </IonContent>
        <IonFooter>
        {this.renderPiePagina()}
      </IonFooter>
      </IonPage>
    )
  }
}

class CatalogoInterno extends React.Component
{
  props: any = {}

  constructor(props: any) {
    super(props)
    this.props = {props}
  }

  render() {
    
    return (
      <CatalogoContext.Consumer>{ catalogoContext =>
        
        <Catalogo props={{...this.props}} catalogoContext={catalogoContext}/>
      }</CatalogoContext.Consumer>
    )
  }
}
export default CatalogoInterno;
