import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonSplitPane,
  IonPage
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import Login from './pages/Login'
import Logout from './pages/Logout'
import Catalogo from './pages/Catalogo'
import Subfamilia from './pages/Subfamilia'
import Articulos from './pages/Articulos'
import DetallesArticulo from './pages/DetallesArticulo'
import Contactar from './pages/Contactar'
import Index from './pages/Index'
import Transparencia from './pages/Transparencia';


import SideMenu from './components/SideMenu';

// contextos
//import CatalogoContext from './contexts/CatalogoContext';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import CatalogoContext from './contexts/CatalogoContext';
import CatalogoContextProvider from './contexts/CatalogoContext';

const App: React.FunctionComponent = () => {
  return (
  <IonReactRouter>
    <div id="app">
    <CatalogoContextProvider>
    <IonApp  lang="es">
            <SideMenu></SideMenu>
            <IonPage id="main">
            <IonRouterOutlet id="main">
              <Route path="/login" component={Login} exact={true} />
              <Route path="/logout" component={Logout} exact={true} />
              <Route path="/catalogo" component={Catalogo} exact={true} />
              <Route path="/subfamilia" component={Subfamilia} exact={true} />
              <Route path="/articulos" component={Articulos} exact={true} />
              <Route path="/detallesArticulo" component={DetallesArticulo} exact={true} />
              <Route path="/contactar" component={Contactar} exact={true} />
              <Route path="/index" component={Index} exact={true} />
              <Route path="/transparencia" component={Transparencia} exact={true} />
              <Route exact path="/" render={() => <Redirect to="/index" />} />
            </IonRouterOutlet>
            </IonPage>
    
  </IonApp>
  </CatalogoContextProvider>
  </div>
  </IonReactRouter>
)};

export default App;
