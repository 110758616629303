import { IonContent, IonTitle, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonPage, IonButton, IonSelectOption, IonToolbar, IonItemDivider, IonFooter } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import { toastController } from '@ionic/core';
import { CatalogoContext } from '../contexts/CatalogoContext';
import './Index.css';
const INITIAL_STATE = {
    menuOpen: false,
    catalogo: []
};

class Index extends React.Component {
  props: any = {};
  state = {menuOpen: false};
  COOKIE_NAME = 'milo-login'
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
    this.setState(this.state)
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
  }
  
  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                {
                  this.successfullyLogin() ?

                  <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie();}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                </div>
                :
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => {this.props.history.push('/login')}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={peopleOutline}></IonIcon>
            ⠀     <span className="textAcceso">Acceso clientes</span>
                </div>
                }
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.history.push('/index');}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/contactar');}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/transparencia');}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/catalogo');}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Inicio</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          
              
          </div>
        </div>
    )
  }

  renderPiePagina() {
    return (
      <div className="fullFooter">
        <div className="colorPiePagina">
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="footerMobile">
          <IonGrid className="gridFooter">
            <IonRow>
              <IonCol>
                <div>
                  <IonLabel>CONTACTAR</IonLabel>
                  <IonItem lines="none" color="grey" className="colorApartadosPiePagina">
                    <div>
                      <div className="subapartadosContactar">
                        <p><IonIcon className="" icon={locationOutline}></IonIcon> Calle El Sino 81</p>
                        <p className="colorSubapartadosContactar">⠀⠀La Esperanza, El Rosario</p>
                        <p>⠀⠀38290 Santa Cruz de Tenerife</p>
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={callOutline}></IonIcon> Teléfono: +34 922 297 618
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={mailOutline}></IonIcon> fabrica@milofood.com
                      </div>
                    </div>
                  </IonItem>
                </div>
              </IonCol>
              <IonCol>
                <IonGrid>
                  <IonRow><IonCol><div className="centroFooter"><IonImg className="logoFooter" src="assets/img/logos/logo_footer.png"></IonImg></div></IonCol></IonRow>
                </IonGrid>
              </IonCol>
              <IonCol>
                <div className="fullWidthClass">
                  <IonCol className= " derechaFooter" size="12">Elaborado en Canarias</IonCol>
                  <IonCol className= " derechaFooter" size="12">Entregas de 24 a 48 horas</IonCol>
                  <IonCol className= " derechaFooter" size="12">Asociados a la plataforma Voxel</IonCol>
                  <IonCol className= " derechaFooter" size="12">Proyectos de inversión de pequeñas y medianas empresas en Canarias para 2020. Definición del proyecto: Mejora de las instalaciones y su capacidad productiva</IonCol>                  
                  <IonCol>
                    <IonRow>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoAvanzaBlanco.png"></IonImg></IonCol>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoGobCanariasBlanco.png"></IonImg></IonCol>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoUnionEuropea.png"></IonImg></IonCol>
                      </IonRow>
                  </IonCol>
                  
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    )
  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
  }


  render() {
      return <IonPage onClick={() => { 
        menuController.enable(true)
          this.setState({menuOpen: false})
      }}>
      <IonHeader>
      
        
        {this.renderEncabezado()}
          
      </IonHeader>
      <IonContent className="ion-padding">
      <IonGrid>
          <IonRow><IonCol size="1"></IonCol><IonCol size="10"><IonText className="titulo"><b>Bienvenido</b> a Milo Food Solutions</IonText></IonCol><IonCol size="1"></IonCol></IonRow>
          <IonRow><IonCol size="1"></IonCol><IonCol size="10"><IonText className="subtexto">Somos una empresa especializada en la elaboración de productos cárnicos, pescado y otros productos alimenticios en base a pescado, con recetas diseñadas para dar el servicio más acorde a cada una de las exigencias de nuestros clientes.</IonText></IonCol><IonCol size="1"></IonCol></IonRow>
          <IonRow><IonCol size="1"></IonCol><IonCol><IonText className="subtexto">Trabajamos con proveedores especializados tanto nacionales como internacionales para poder ofrecer los productos de mejor calidad.</IonText></IonCol><IonCol size="1"></IonCol></IonRow>
          </IonGrid>
          <div className="separadorFinal">⠀</div>
            
          
      </IonContent>
      
      <IonFooter>
      {this.renderPiePagina()}
      </IonFooter>
    </IonPage>
  }
}

export default Index;