import { IonContent, IonTitle, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonPage, IonButton, IonSelectOption, IonToolbar, IonItemDivider, IonFooter } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, caretDownOutline, caretForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import { toastController } from '@ionic/core';
import './Index.css';
import './Transparencia.css'
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { h } from 'ionicons/dist/types/stencil-public-runtime';
const INITIAL_STATE = {
    menuOpen: false,
    catalogo: [],
    hide: [true, true, true, true, true]
};

class Transparencia extends React.Component {
  props: any = {};
  state = {menuOpen: false, hide: [true]};
  COOKIE_NAME = 'milo-login'
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
    //menuController.enable(this.state.menuOpen)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                {
                  this.successfullyLogin() ?

                  <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie();}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                </div>
                :
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => {this.props.history.push('/login')}}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={peopleOutline}></IonIcon>
            ⠀     <span className="textAcceso">Acceso clientes</span>
                </div>
                }
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/index');}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/contactar');}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.history.push('/transparencia');}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.history.push('/catalogo');}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Transparencia</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
  }

  renderPiePagina() {
    return (
      <div className="fullFooter">
        <div className="colorPiePagina">
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="footerMobile">
          <IonGrid className="gridFooter">
            <IonRow>
              <IonCol>
                <div>
                  <IonLabel>CONTACTAR</IonLabel>
                  <IonItem lines="none" color="grey" className="colorApartadosPiePagina">
                    <div>
                      <div className="subapartadosContactar">
                        <p><IonIcon className="" icon={locationOutline}></IonIcon> Calle El Sino 81</p>
                        <p className="colorSubapartadosContactar">⠀⠀La Esperanza, El Rosario</p>
                        <p>⠀⠀38290 Santa Cruz de Tenerife</p>
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={callOutline}></IonIcon> Teléfono: +34 922 297 618
                      </div>
                      <div className="subapartadosContactar">
                        <IonIcon icon={mailOutline}></IonIcon> fabrica@milofood.com
                      </div>
                    </div>
                  </IonItem>
                </div>
              </IonCol>
              <IonCol>
                <IonGrid>
                  <IonRow><IonCol><div className="centroFooter"><IonImg className="logoFooter" src="assets/img/logos/logo_footer.png"></IonImg></div></IonCol></IonRow>
                </IonGrid>
              </IonCol>
              <IonCol>
                <div className="fullWidthClass">
                  <IonCol className= " derechaFooter" size="12">Elaborado en Canarias</IonCol>
                  <IonCol className= " derechaFooter" size="12">Entregas de 24 a 48 horas</IonCol>
                  <IonCol className= " derechaFooter" size="12">Asociados a la plataforma Voxel</IonCol>
                  <IonCol className= " derechaFooter" size="12">Proyectos de inversión de pequeñas y medianas empresas en Canarias para 2020. Definición del proyecto: Mejora de las instalaciones y su capacidad productiva</IonCol>                  
                  <IonCol>
                    <IonRow>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoAvanzaBlanco.png"></IonImg></IonCol>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoGobCanariasBlanco.png"></IonImg></IonCol>
                        <IonCol size="4"><IonImg className="logosSubvencion" src="assets/img/logos/logoUnionEuropea.png"></IonImg></IonCol>
                      </IonRow>
                  </IonCol>
                  
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    )
  }

  renderTransparencia() {
    return <div className="estiloTransparencia">
        <div onClick={() => {this.state.hide[0] = !this.state.hide[0]}} className="encabezadoApartado cursosSeleccionable">
          Misión, visión y valores
          {
            this.state.hide[0] ?
              <IonIcon className="iconoDesplegarTransparencia" icon={caretForwardOutline}></IonIcon>
            :
              <IonIcon className="iconoDesplegarTransparencia" icon={caretDownOutline}></IonIcon>
          }
        </div>
        {
          this.state.hide[0] ?
          <div></div>
          :
          <div>
            <p className="contenidoApartado">MILO FOOD SOLUTIONS SL es una empresa especializada en la elaboración de productos cárnicos, pescado y otros productos alimenticios en base a pescado, con recetas diseñadas para dar el servicio más acorde a cada una de las exigencias de nuestros clientes.</p>
            <p className="contenidoApartado">Contamos con proveedores especializados tanto nacionales como internacionales para poder ofrecer los productos de mejor calidad y adaptarnos a las necesidades de cada uno de nuestros clientes.</p>
            <p className="contenidoApartado">La reciente implantación del sistema integrado de calidad y sistema de administración de la inocuidad de los alimentos basados en las normas UNE-EN ISO 9001:2015 e ISO 22000:2018 garantiza el cumplimiento de las premisas de la empresa desde sus inicios:</p>
            <ol>
              <li className="contenidoApartado">Gestionar de forma ética nuestra actividad diaria basándonos en los valores corporativos, el código de conducta y el sistema de cumplimiento</li>
              <li className="contenidoApartado">Desplegar un modelo propio, integral y sistémico de gestión de los grupos de interés que implica:</li>
              <ul>
                <li>Su adecuada identificación.</li>
                <li>El establecimiento de mecanismos estables de relación y herramientas de escucha activa.</li>
                <li>La priorización de los asuntos que les son relevantes y contribuyan a la mayor creación de valor de la Compañía.</li>
                <li>La incorporación de los asuntos materiales identificados en la estrategia de la Compañía y en sus planes operativos.</li>
                <li>El seguimiento sistemático de los planes y objetivos definidos.</li>
                <li>La comunicación interna y externa y el reporte del estado de situación y logros alcanzados.</li>
              </ul>
              <li className="contenidoApartado">Identificar y gestionar de manera anticipativa los potenciales impactos de la actividad, sean de naturaleza económica, laboral, ética, medioambiental o social, para lograr un desempeño sostenible con visión y perspectiva de largo plazo.</li>
              <li className="contenidoApartado">Desarrollar y desplegar un modelo de gestión propio basado en la mejora continua y la excelencia de todos nuestros procesos y actividades.</li>
              <li className="contenidoApartado">Cumplir tanto con los requerimientos legales que nos son de aplicación como con las exigencias específicas de nuestros clientes.</li>
              <li className="contenidoApartado">Garantizar la implantación en nuestra empresa y el traslado a nuestros clientes de una cultura de seguridad alimentaria.</li>
            </ol>
          </div>
        }
      
        <div onClick={() => {this.state.hide[1] = !this.state.hide[1]}} className="encabezadoApartado cursosSeleccionable">
          Información en materia normativa
          {
            this.state.hide[1] ?
              <IonIcon className="iconoDesplegarTransparencia" icon={caretForwardOutline}></IonIcon>
            :
              <IonIcon className="iconoDesplegarTransparencia" icon={caretDownOutline}></IonIcon>
          }
        </div>
        {
          this.state.hide[1] ?
            <div></div>
          :
            <p className="contenidoApartado">Enlace a <span className="estiloLink" onClick={() => {InAppBrowser.create("assets/pdf/transparencia/A2_Lista_Legislacion.pdf")/*InAppBrowser.create("https://drive.google.com/file/d/1DrcmS1cRbOkpfrAxypb9SKbXu1hhWPYy/view?usp=sharing")*/}}>A2 Lista Legislación.</span></p>
        }
        <div onClick={() => {this.state.hide[2] = !this.state.hide[2]}} className="encabezadoApartado cursosSeleccionable">
          Objeto social o al fin fundacional de la entidad
          {
            this.state.hide[2] ?
              <IonIcon className="iconoDesplegarTransparencia" icon={caretForwardOutline}></IonIcon>
            :
              <IonIcon className="iconoDesplegarTransparencia" icon={caretDownOutline}></IonIcon>
          }
        </div>
        {
          this.state.hide[2] ?
            <div></div>
          :
          <p className="contenidoApartado">Proveer de un producto de calidad siguiendo los más altos estándares en materia de seguridad y calidad alimentaria a nuestros clientes.</p>
        }
        <div onClick={() => {this.state.hide[3] = !this.state.hide[3]}} className="encabezadoApartado cursosSeleccionable">
          Información en materia de retribuciones
          {
            this.state.hide[3] ?
              <IonIcon className="iconoDesplegarTransparencia" icon={caretForwardOutline}></IonIcon>
            :
              <IonIcon className="iconoDesplegarTransparencia" icon={caretDownOutline}></IonIcon>
          }
        </div>
        {
          this.state.hide[3] ?
            <div></div>
          :
            <p className="contenidoApartado">Tanto el personal administrativo, como el equipo que se encarga de la producción así como de la distribución, tendrán salario fijo en base a la legalidad del convenio determinado. La empresa se compromete a realizar el pago de la retribución en los primeros días del mes. También hay la opción de realizar pagos en especie.</p>
        }
        <div onClick={() => {this.state.hide[4] = !this.state.hide[4]}} className="encabezadoApartado cursosSeleccionable">
          Ayudas y subvenciones
          {
            this.state.hide[4] ?
              <IonIcon className="iconoDesplegarTransparencia" icon={caretForwardOutline}></IonIcon>
            :
              <IonIcon className="iconoDesplegarTransparencia" icon={caretDownOutline}></IonIcon>
          }
        </div>
        {
          this.state.hide[4] ?
            <div></div>
          :
            <p className="contenidoApartado">Enlace a <span className="estiloLink" onClick={() => {InAppBrowser.create("assets/pdf/transparencia/Resumen_subvencion.pdf")}}>Ayudas y subvenciones.</span></p>
        }
    </div>
  }


  render() {
      return <IonPage onClick={() => { 
        menuController.enable(true)
        this.setState({menuOpen: false})
      }}>
      <IonHeader>
          
        {this.renderEncabezado()}
          
      </IonHeader>
      <IonContent className="">
       
            {this.renderTransparencia()}

      </IonContent>
      
      <IonFooter>
      {this.renderPiePagina()}
      </IonFooter>
    </IonPage>
  }
}

export default Transparencia;