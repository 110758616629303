import { IonContent, IonTitle, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonPage, IonButton, IonSelectOption, IonToolbar } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import { toastController } from '@ionic/core';
import { CatalogoContext } from '../contexts/CatalogoContext';

const INITIAL_STATE = {
    menuOpen: false,
    catalogo: []
};

class Articulos extends React.Component {
  props: any = {};
  state = {menuOpen: false};
  COOKIE_NAME = 'milo-login'
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
    //menuController.enable(this.state.menuOpen)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
  }

  renderPath(){
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] === undefined)
      return <div></div>
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada] === undefined)
      return <div></div>
    return (
      <IonRow>
        <div className="cursosSeleccionable" onClick={() => {
          
            this.props.props.history.push('/catalogo')
          }}>
          Catálogo
        </div>
        <IonIcon icon={chevronForwardOutline}></IonIcon>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/subfamilia')
          }}>
          {this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia}
        </div>
        <IonIcon icon={chevronForwardOutline}></IonIcon>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/articulos')
          }}>
          {this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].nombreSubfamilia}
        </div>
      </IonRow>
    )
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }
  

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie(); this.props.props.history.push('/login')/*this.props.props.history.push('/logout')*/ }}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                </div>
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/index');}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/contactar');}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/transparencia');}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.props.history.push('/catalogo');}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Artículos</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }
  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
}

  renderPiePagina() {
    return (
        <div>
            <div className="colorPiePagina">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
              </IonRow>
            </IonGrid>
            </div>
        </div>
    )
  } 
  mostrarConservacion(articulo:any) {
    if(articulo.conservacion === "Congelado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/frozenIcon.png"></IonImg></IonCol>
    if(articulo.conservacion === "Refrigerado")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/chilledIcon.png"></IonImg></IonCol>
    return <div></div>
      /*
    if(articulo.conservacion === "Seco")
      return <IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol>
      */
  }

  mostrarCaracteristicas(articulo:any) {
    return <IonRow><IonCol>⠀</IonCol>{this.mostrarConservacion(articulo)}<IonCol>⠀</IonCol></IonRow>
    console.log(articulo.conservacion)
    return <div></div>
    return <IonRow><IonCol><IonImg className="iconoCaracteristica" src="assets/icon/icon.png"></IonImg></IonCol></IonRow>

  }


  renderArticulos(articulos:any) {
    return articulos.map((articulo:any) => (
      <IonItem className="cursosSeleccionable sizeCategoria">
        <IonGrid>
          <IonRow>
            <IonImg className="sizeFotoCategoria" src={"assets/img/Articulos/" + articulo.codigoArticulo + ".png"} /*src={articulo.rutaImagen}*/  onClick={() => {
                this.props.catalogoContext.articuloSeleccionado = articulo.idVector
                this.props.props.history.push('/detallesArticulo')
            }}></IonImg>
          </IonRow>
          {this.mostrarCaracteristicas(articulo)}
          <IonRow> <IonCol><div className="pieDeFoto">{articulo.codigoArticulo} - {articulo.nombreArticulo}</div></IonCol></IonRow>
        </IonGrid>
      </IonItem>
    ))
  }

  render() {
    if(this.props.catalogoContext.catalogo.length === 0) {
      this.props.catalogoContext.subfamiliaSeleccionada = 0
      this.props.catalogoContext.familiaSeleccionada = 0
      this.props.catalogoContext.articuloSeleccionado = 0
      this.props.props.history.push('/login')
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] === undefined) {
      this.props.catalogoContext.subfamiliaSeleccionada = 0
      this.props.catalogoContext.familiaSeleccionada = 0
      this.props.catalogoContext.articuloSeleccionado = 0
      this.props.props.history.push('/login')
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia === undefined) {
      this.props.catalogoContext.subfamiliaSeleccionada = 0
      this.props.catalogoContext.familiaSeleccionada = 0
      this.props.catalogoContext.articuloSeleccionado = 0
      this.props.props.history.push('/login')
      return <div></div>
    }
      return <IonPage onClick={() => { 
        menuController.enable(true)
        this.setState({menuOpen: false})
      }}>
      <IonHeader>
        {this.renderEncabezado()}
        <div className="headerPath">
          {this.renderPath()}
        </div>
      </IonHeader>
      <IonContent>
        
        <IonRow>
          { this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] === undefined || this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada] === undefined ?
          <div></div>
          :
          this.renderArticulos(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos)
          }
        </IonRow>
        
    </IonContent>
    {this.renderPiePagina()}
    </IonPage>
  }
}

class ArticulosInterno extends React.Component
{
  props: any = {}

  constructor(props: any) {
    super(props)
    this.props = {props}
  }

  render() {
    
    return (
      <CatalogoContext.Consumer>{ catalogoContext =>
        <Articulos props={{...this.props}} catalogoContext={catalogoContext} />
      }</CatalogoContext.Consumer>
    )
  }
}
export default ArticulosInterno;