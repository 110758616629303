import { IonContent, IonTitle, IonIcon, IonImg, IonInput, IonRow, IonLabel, IonItem, IonGrid, IonText, IonTextarea, IonCol, IonHeader, IonPage, IonButton, IonSelectOption, IonToolbar, IonFooter } from '@ionic/react';
import React  from 'react';
import axios from 'axios';
import { menuController } from '@ionic/core';
import { menu, addOutline, trashBinOutline, chevronForwardOutline, peopleOutline, locationOutline, callOutline, mailOutline, logInOutline } from 'ionicons/icons';
import { toastController } from '@ionic/core';
import { CatalogoContext } from '../contexts/CatalogoContext';
import './DetallesArticulo.css';

const INITIAL_STATE = {
    menuOpen: false,
    catalogo: []
};

class DetallesArticulo extends React.Component {
  props: any = {};
  state = {menuOpen: false};
  COOKIE_NAME = 'milo-login'
  constructor(props: any) {
    super(props);
    this.props = props
    this.state = INITIAL_STATE
    this.setState(INITIAL_STATE)
    
    //menuController.enable(this.state.menuOpen)
  }

  ionViewDidEnter(): void {
    menuController.enable(false);
  }
  
  ionViewDidLeave(): void {
    menuController.enable(true);
  }
  
  renderPath() {
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada] === undefined || this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos === undefined || this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado] === undefined)
      return <div></div>
    return (
      <div className="estiloPath">
      <IonRow>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/catalogo')
          }}>
          Catálogo
        </div>
        <IonIcon icon={chevronForwardOutline}></IonIcon>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/subfamilia')
          }}>
          {this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia}
        </div>
        <IonIcon icon={chevronForwardOutline}></IonIcon>
        <div className="cursosSeleccionable" onClick={() => {
            this.props.props.history.push('/articulos')
          }}>
          {this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].nombreSubfamilia}
        </div>
        
      </IonRow>
      </div>
    )
  }

  parseObservaciones(observaciones:string) {
    while(observaciones.search("_x000D_") !== -1)
      observaciones = observaciones.replace("_x000D_",'')
    
    return observaciones.toString()
  }

  renderObservaciones(observaciones:string) {
    //console.log(this.parseObservaciones(observaciones))
    return <div>{this.parseObservaciones(observaciones)}</div>
  }

  renderArticulo(articulo:any) {
    if(articulo === undefined)
      return <div></div>
    //console.log(articulo)
    return  <div>
              <div className="headerDesktop">
                <IonItem lines="none" className="contenedorArticulo">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2"></IonCol>
                        <IonCol size="4"><IonImg className="fotoArticulo" src={"assets/img/Articulos/" + articulo.codigoArticulo + ".png"}></IonImg></IonCol>
                        <IonCol size="4">
                          <IonCol>
                            <div className="textoArticulo">
                              <IonCol size="12">{articulo.codigoArticulo} - {articulo.nombreArticulo}</IonCol>
                              <IonCol size="12">Conservación: {articulo.conservacion}</IonCol>
                              <IonCol size="12">Preparación: {articulo.preparacion}</IonCol>
                              <IonCol size="12">Peso: {articulo.peso}</IonCol>
                              <IonCol size="12">Formato: {articulo.formato}</IonCol>
                              <IonCol size="12">{articulo.observaciones === null ? <div></div> : <div>{this.renderObservaciones(articulo.observaciones)}</div>}</IonCol>
                            </div>
                          </IonCol>
                        </IonCol>
                        <IonCol size="3"></IonCol>
                      </IonRow>

                    </IonGrid>
                  </IonItem>
              </div>
              <div className="headerMobile">
                <div className="contenedorArticuloMovil">
                  <IonImg className="imagenArticuloMovil" src={"assets/img/Articulos/" + articulo.codigoArticulo + ".png"}></IonImg>
                  <div className="textoArticuloMovil">

                    <IonItem>
                      <div>
                        <p>{articulo.codigoArticulo} - {articulo.nombreArticulo}</p>
                        <p>Conservación: {articulo.conservacion}</p>
                        <p>Preparación: {articulo.preparacion}</p>
                        <p>Peso: {articulo.peso}</p>
                        <p>Formato: {articulo.formato}</p>
                        <p>{articulo.observaciones === null ? <div></div> : <div>{articulo.observaciones}</div>}</p>
                      </div>
                    </IonItem>
                    
                              
                  </div>
                </div>
              </div>
            </div>
  }

  borrarCookie() {
    //document.cookie = this.COOKIE_NAME + "=" + "" + -1 + "; path=/";
    this.crearCookie(this.COOKIE_NAME, "", -1)
  }

  crearCookie(name:string, token:string, days:number) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    else {
        expires = "";
    }
    //console.log(name + "=" + token + expires + "; path=/")
    document.cookie = name + "=" + token + expires + "; path=/";
  }

  renderEncabezado() {
    return (
        <div className="container correccionDeFondo">
          <div className="headerDesktop">
          <IonHeader className="encabezadoEspaciado">
            <div className="barraEncabezado">
                <div className="encabezadoIzquierda">
                    +34 922 539 369 &bull; Atención al cliente 
                </div>                   
                <div className="encabezadoDerecha cursosSeleccionable" onClick={() => { this.borrarCookie(); this.props.props.history.push('/login')/*this.props.props.history.push('/logout')*/ }}>
                  <IonIcon class="iconAcceso ion-ios-clock-outline" icon={logInOutline}></IonIcon>
          ⠀       <span className="textAcceso">Cerrar sesión</span>
                </div>
          
          </div>
          </IonHeader>

          <IonGrid>
          <IonRow><IonCol size="4"><IonImg className="logoEncabezado correccionDeFondo cursosSeleccionable" src="assets/img/logos/logo.png" onClick={() => {this.props.props.history.push('/index');}}></IonImg></IonCol>
          <IonCol size="4"></IonCol>
          <IonCol size="4">
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/index');}}>Inicio </div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/contactar');}}>Contactar</div>
            <div className="botonesBarra cursosSeleccionable" onClick={() => {this.props.props.history.push('/transparencia');}}>Transparencia</div>
            {this.successfullyLogin() ? <div className="botonesBarra cursosSeleccionable categoriaActual" onClick={() => {this.props.props.history.push('/catalogo');}}>Catálogo</div> : <div></div>}
        </IonCol>
        </IonRow>
          </IonGrid>
          </div>
          <div className="headerMobile">
            <IonGrid>
              <IonRow>
                <IonCol size="2">
                  <IonIcon icon={menu} id="icono_menu" onClick={() => { 
                    if(this.state.menuOpen) {
                      menuController.enable(false)
                      this.setState({menuOpen: false})
                    }
                    else {
                      menuController.enable(true)
                      menuController.open()
                      this.setState({menuOpen: true})
                    }
                }}/>
                </IonCol>
                <IonCol size="1">
                  <IonTitle>Detalles</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
    )
  }

  leerCookies() {
    let cookies = document.cookie.split(" ")
    for(let i = 0; i < cookies.length; i++)
      if(cookies[i].substring(0, cookies[i].indexOf("=")) === this.COOKIE_NAME)
        return cookies[i]
    return undefined
}

  successfullyLogin() {
    let cookie = this.leerCookies()
    if(cookie === undefined)
      return false
    return true
  }

  renderPiePagina() {
    return (
        <div>
            <div className="colorPiePagina">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="pieDerechos">Milo Food Solutions. Todos los derechos reservados</IonCol>
              </IonRow>
            </IonGrid>
            </div>
        </div>
    )
  } 

  render() {
    if(this.props.catalogoContext.catalogo.length === 0) {
      this.props.props.history.push('/login')
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] === undefined) {
      this.props.props.history.push('/login')
      return <div></div>
    }
    if(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].nombreFamilia === undefined) {
      this.props.props.history.push('/login')
      return <div></div>
    }
    return <IonPage onClick={() => { 
      menuController.enable(true)
      this.setState({menuOpen: false})
    }}>
      <IonHeader>
        {this.renderEncabezado()}
        <div className="headerPath">
          {this.renderPath()}
        </div>
        </IonHeader>
      <IonContent className="">
        
        {
          this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada] !== undefined &&
            this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias !== undefined &&
            this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada] !== undefined &&
            this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos !== undefined &&
            this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado] !== undefined
            ?
          this.renderArticulo(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado])
          :<div></div>
          }
        {/*console.log(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado])*/}
        {/*this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado]*/}
        {/*this.renderArticulo(this.props.catalogoContext.catalogo[this.props.catalogoContext.familiaSeleccionada].subfamilias[this.props.catalogoContext.subfamiliaSeleccionada].articulos[this.props.catalogoContext.articuloSeleccionado])*/}
        
    </IonContent>
    <IonFooter>
    {this.renderPiePagina()}
    </IonFooter>
    </IonPage>
  }
}

class DetallesArticuloInterno extends React.Component
{
  props: any = {}

  constructor(props: any) {
    super(props)
    this.props = {props}
  }

  render() {
    
    return (
      <CatalogoContext.Consumer>{ catalogoContext =>
        <DetallesArticulo props={{...this.props}} catalogoContext={catalogoContext} />
      }</CatalogoContext.Consumer>
    )
  }
}
export default DetallesArticuloInterno;